import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';

const Input = styled.input`
  padding: 10px 15px;
  border-radius: 30px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
  line-height: 20px;
  flex: 0 1 200px;
`;
const Button = styled.input`
  padding: 10px 15px;
  border-radius: 30px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: none;
  background: hsl(209, 61%, 16%);
  color: hsl(210, 36%, 96%);
  cursor: pointer;
`;

const InputWrapper = styled.div``;

const Message = styled.div`
  background: ${props => (props.type === 'error' ? 'hsl(360, 79%, 32%)' : 'hsl(185, 84%, 25%)')};
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  a {
    color: #fff;
  }
`;

const Form = styled.form`
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #fff;
  background: ${props => (props.checked ? 'hsl(209, 61%, 16%)' : 'transparent')};
  border-radius: 3px;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const CheckboxWrapper = styled.div`
  margin: 0px auto;
  margin-bottom: 20px;
  label {
    padding: 15px;
    span {
      margin-left: 5px;
    }
  }
`;

const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      iosChecked: false,
      androidChecked: false,
      result: {
        msg: '',
        result: '',
      },
    };
  }

  _handleSubmit = async e => {
    const { email, iosChecked, androidChecked } = this.state;
    e.preventDefault();
    const result = await addToMailchimp(email, {
      IOS: `${iosChecked}`,
      ANDROID: `${androidChecked}`,
    });
    this.setState({ result, email: '', iosChecked: false, androidChecked: false });
    // I recommend setting `result` to React state
    // but you can do whatever you want
  };

  _handleEmailChange = email => {
    this.setState({ email });
  };

  handleCheckboxChange = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  render() {
    const { email, result, iosChecked, androidChecked } = this.state;
    const buttonText = this.props.intl.formatMessage({ id: 'signUp' });
    return (
      <>
        <Form onSubmit={e => this._handleSubmit(e)}>
          <CheckboxWrapper>
            <label htmlFor="iosChecked"> {/* eslint-disable-line */}
              <Checkbox checked={iosChecked} onChange={this.handleCheckboxChange} id="iosChecked" name="iosChecked" />
              <span>iPhone / iPad</span>
            </label>
            <label htmlFor="androidChecked"> {/* eslint-disable-line */}
              <Checkbox
                checked={androidChecked}
                onChange={this.handleCheckboxChange}
                id="androidChecked"
                name="androidChecked"
              />
              <span>Android</span>
            </label>
          </CheckboxWrapper>
          <InputWrapper>
            <Input
              type="email"
              placeholder="you@awesome.com"
              value={email}
              onChange={e => this._handleEmailChange(e.target.value)}
            />
            <Button type="submit" value={buttonText} />
          </InputWrapper>
        </Form>
        {result && result.msg && (
          <Message type={result.result}>
            <p dangerouslySetInnerHTML={{ __html: result.msg }} />
          </Message>
        )}
      </>
    );
  }
}

export default injectIntl(Signup);
