import React from 'react';
import PropTypes from 'prop-types';
import { Spring } from 'react-spring';
import { withPrefix } from 'gatsby';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import SEO from '../seo';
import Signup from '../signup';

import MainSection from '../../styles/MainSection';
import Social from '../social';
import PumaPhone from '../pumaPhone';

const IndexPage = ({ intl, pageContext }) => (
  <>
    <SEO title="Home" />
    <MainSection stackOnMobile>
      <Spring from={{ opacity: 0, right: -40 }} to={{ opacity: 1, right: 0 }}>
        {props => (
          <div className="image-container" style={props}>
            <div className="logo-container">
              <PumaPhone />
            </div>
          </div>
        )}
      </Spring>
      <Spring from={{ opacity: 0, bottom: -40 }} to={{ opacity: 1, bottom: 0 }}>
        {props => (
          <div className="blurb-container" style={props}>
            <h1>
              <FormattedMessage id="title" />{' '}
            </h1>
            <div className="content">
              <p>
                <FormattedMessage id="privacyBlurb" />
              </p>
              <p>
                <FormattedMessage id="earlyPreviewBlurb" />
              </p>
              <p>
                <FormattedMessage id="signUpBlurb" />
              </p>
              <Signup />
              <Social />
              <div className="download-links">
                <a className="apple" href="https://itunes.apple.com/us/app/puma-browser-monetize-the-web/id1456296154">
                  <img
                    src={withPrefix(intl.formatMessage({ id: 'appStore' }))}
                    width={200}
                    alt="Download on the App store"
                  />
                </a>
                <a className="google" href="https://play.google.com/store/apps/details?id=com.pumabrowser.pumabrowser">
                  <img
                    src={withPrefix(intl.formatMessage({ id: 'gStore' }))}
                    width={200}
                    alt="Download on Google Play"
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </Spring>
    </MainSection>
  </>
);

IndexPage.propTypes = {
  intl: intlShape.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default injectIntl(IndexPage);
